<script>
	import { afterNavigate } from '$app/navigation';
	import { page } from '$app/stores';

	afterNavigate(() => {
		window._paq = window._paq || [];
		if ($page?.data?.experimentGroup) {
			window._paq.push([
				'setCustomDimensionValue',
				2,
				$page?.data?.experimentGroup,
			]);
		}
		window._paq.push(['setCustomUrl', $page.url.toString()]);
		window._paq.push(['setDocumentTitle', $page.data.title]);
		window._paq.push(['trackPageView']);
	});
</script>

<svelte:head>
	<script>
		window._paq = window._paq || [];
		window._paq.push(['enableLinkTracking']);
		window._paq.push(['setIpTracking', true]);
		window._paq.push(['setDomains', ['indowwindows.com']]);
		window._paq.push(['enableHeartBeatTimer']);
		window._paq.push([
			'setTrackerUrl',
			'https://indowwindows.piwik.pro/ppms.php',
		]);
		window._paq.push(['setSiteId', 'f42dab66-21c7-49d7-9533-b497b65f6079']);
	</script>

	<!-- eslint-disable-next-line -->
	{@html '<script type="text/javascript" async defer src="/static/js/ppms.js"></script>'}
</svelte:head>
